import React from 'react';
import * as FormStyling from "../Form.module.scss";

const TermsConditions = ({ errors, register }) => {
  return (
    <div className="field is-valid">
      <label className="inline-label">
        <input
          className={FormStyling.formInput}
          type="checkbox"
          {...register("terms", { required: true })}
        />
        <span className={FormStyling.aboutMessage}>
          I accept Terms & Conditions & Privacy Policy.
        </span>
      </label>
      {errors.terms && (
        <span className="error field-message" role="alert">
          Must be checked
        </span>
      )}
    </div>
  );
};

export default TermsConditions;
